import VUpdateModule from '@/views/modules/VUpdateModule.vue'
import VUsersList from '@/views/users/VUsersList.vue'
import VAddUser from '@/views/users/VAddUser.vue'
import VEditQuiz from '@/views/quiz/VEditQuiz.vue'
import { createAuthGuard } from '@auth0/auth0-vue'
import { Role } from '@/assets/types/UserTypes'

import {
  VAddLearningCourse,
  VLearningCourse,
  VLearningCoursesList,
  VUpdateLearningCourse
} from '@/views/learningCourses'
import type { RouteLocationNormalized } from 'vue-router'
import VDashboardGroupList from '@/views/dashboard/VDashboardGroupList.vue'
import VDashboardGroupDetails from '@/views/dashboard/VDashboardGroupDetails.vue'
import VDashboardGroupProgress from '@/views/dashboard/VDashboardGroupProgress.vue'
import VDashboard from '@/views/dashboard/VDashboard.vue'

/**
 * Auth0 guard
 */
const authGuard = createAuthGuard()

export const routes = [
  {
    path: '/tableau-de-bord',
    alias: ['/'],
    component: VDashboard,
    redirect: '/tableau-de-bord',
    meta: {
      group: 'dashboard',
      icon: 'pi-home',
      menuTitle: 'Tableau de bord',
      menuOrder: 1
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: VDashboardGroupList,
        beforeEnter: [authGuard],
        meta: {
          group: 'dashboard',
          withSidebar: true,
          roles: [Role.Values.ADMIN, Role.Values.TEACHER]
        }
      },
      {
        path: 'etablissements/:schoolId(\\d+)/groupes/:groupId(\\d+)',
        name: 'dashboard-group',
        component: VDashboardGroupDetails,
        beforeEnter: [authGuard],
        props: (route: RouteLocationNormalized) => ({
          schoolId: Number(route.params.schoolId),
          groupId: Number(route.params.groupId)
        }),
        meta: {
          group: 'dashboard',
          withSidebar: true,
          roles: [Role.Values.ADMIN, Role.Values.TEACHER]
        }
      },
      {
        path: 'etablissements/:schoolId(\\d+)/groupes/:groupId(\\d+)/resultat-du-parcours/:courseId(\\d+)',
        name: 'dashboard-group-progress',
        component: VDashboardGroupProgress,
        beforeEnter: [authGuard],
        props: (route: RouteLocationNormalized) => ({
          schoolId: Number(route.params.schoolId),
          groupId: Number(route.params.groupId),
          courseId: Number(route.params.courseId)
        }),
        meta: {
          group: 'dashboard',
          withSidebar: true,
          roles: [Role.Values.ADMIN, Role.Values.TEACHER]
        }
      }
    ]
  },
  {
    path: '/utilisateurs',
    beforeEnter: [authGuard],
    meta: {
      group: 'users',
      icon: 'pi-user',
      menuTitle: 'Utilisateurs',
      menuOrder: 2
    },
    children: [
      {
        path: '',
        name: 'users-list',
        component: VUsersList,
        beforeEnter: [authGuard],
        meta: {
          group: 'users',
          withSidebar: true,
          roles: [Role.Values.ADMIN]
        }
      },
      {
        path: 'ajouter',
        name: 'create-user',
        component: VAddUser,
        beforeEnter: [authGuard],
        meta: {
          group: 'users',
          withSidebar: true,
          roles: [Role.Values.ADMIN]
        }
      },
      {
        path: 'modifier/:userIdentifier',
        name: 'edit-user',
        component: VAddUser,
        beforeEnter: [authGuard],
        meta: {
          group: 'users',
          withSidebar: true,
          roles: [Role.Values.ADMIN]
        }
      }
    ]
  },
  {
    path: '/parcours',
    beforeEnter: [authGuard],
    meta: {
      group: 'learning-courses',
      icon: 'pi-th-large',
      menuTitle: 'Parcours',
      menuOrder: 3
    },
    children: [
      {
        path: '',
        name: 'learning-courses-list',
        component: VLearningCoursesList,
        beforeEnter: [authGuard],
        meta: {
          group: 'learning-courses',
          withSidebar: true,
          roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
        }
      },
      {
        path: 'ajouter',
        name: 'learning-courses-add',
        component: VAddLearningCourse,
        beforeEnter: [authGuard],
        meta: {
          group: 'learning-courses',
          withSidebar: true,
          roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
        }
      },
      {
        path: ':id(\\d+)',
        component: VLearningCourse,
        beforeEnter: [authGuard],
        props: (route: RouteLocationNormalized) => ({ id: Number(route.params.id) }),
        children: [
          {
            path: 'modifier',
            name: 'learning-course-update',
            component: VUpdateLearningCourse,
            beforeEnter: [authGuard],
            meta: {
              group: 'learning-courses',
              withSidebar: true,
              roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
            }
          },
          {
            path: 'quiz/:quizId(\\d+)',
            name: 'learning-course-update-quiz',
            component: VEditQuiz,
            beforeEnter: [authGuard],
            props: (route: RouteLocationNormalized) => ({
              id: Number(route.params.id),
              quizId: Number(route.params.quizId),
              taskId: Number(route.query.taskId)
            }),
            meta: {
              group: 'learning-courses',
              withSidebar: true,
              roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
            }
          },
          {
            path: 'modules',
            children: [
              {
                path: 'ajouter',
                name: 'learning-course-add-module',
                component: VUpdateModule,
                beforeEnter: [authGuard],
                meta: {
                  group: 'learning-courses',
                  withSidebar: true,
                  roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                }
              },
              {
                path: ':moduleId(\\d+)',
                children: [
                  {
                    path: '',
                    name: 'learning-course-update-module',
                    component: VUpdateModule,
                    beforeEnter: [authGuard],
                    props: (route: RouteLocationNormalized) => ({
                      id: Number(route.params.id),
                      moduleId: Number(route.params.moduleId)
                    }),
                    meta: {
                      group: 'learning-courses',
                      withSidebar: true,
                      roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                    }
                  },
                  {
                    path: 'grain/:grainId(\\d+)/quiz/:quizId(\\d+)',
                    name: 'learning-grain-update-quiz',
                    component: VEditQuiz,
                    beforeEnter: [authGuard],
                    props: (route: RouteLocationNormalized) => ({
                      id: Number(route.params.id),
                      moduleId: Number(route.params.moduleId),
                      grainId: Number(route.params.grainId),
                      quizId: Number(route.params.quizId)
                    }),
                    meta: {
                      group: 'learning-courses',
                      withSidebar: true,
                      roles: [Role.Values.ADMIN, Role.Values.EDU_ENGINEER]
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // Route catch-all
    name: 'NotFound',
    redirect: '/'
  }
]
