import PrimeVue from 'primevue/config'
import type { App } from 'vue'

import 'primevue/resources/primevue.min.css'
import { createPinia } from 'pinia'
import router from '@/router'
import ToastService from 'primevue/toastservice'
import confirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'

import { PrimeVueConfig } from '@/plugins/primeVue'
import { AdminSentry } from '@/plugins/sentry'
import auth0Client from '@/plugins/auth0'
import Tooltip from 'primevue/tooltip'
import { registerIconsToLibrary } from '@/plugins/icons'
import locale = PrimeVueConfig.locale

export function registerPlugins(app: App) {
  registerIconsToLibrary()
  app.directive('tooltip', Tooltip)
  app.use(createPinia())
  app.use(ToastService)
  app.use(DialogService)
  app.use(router)
  app.use(PrimeVue, {
    ripple: true,
    locale: locale.fr
  })

  app.use(confirmationService)

  // Intégration de Auth0
  app.use(auth0Client)

  AdminSentry.init(app)
}
