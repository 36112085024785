<script setup lang="ts">
import DefaultTemplate from '@/components/templates/DefaultTemplate.vue'

import { default as PToast } from 'primevue/toast'
import { default as PConfirmDialog } from 'primevue/confirmdialog'
import { default as PDynamicDialog } from 'primevue/dynamicdialog'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useMagicKeys } from '@vueuse/core'
import { onMounted, watch } from 'vue'

const { initLogin, loginWithRedirect } = useAuthStore()
const { isAuthenticated } = storeToRefs(useAuthStore())

// Get the router
const router = useRouter()

// Cheats to get current version of the app
const keys = useMagicKeys()
const versionKeys = keys['Ctrl+Alt+w']
watch(versionKeys, async (pressed) => {
  if (pressed) alert(`Version : ${__APP_NAME__}@${__APP_VERSION__}`)
})

onMounted(async () => {
  await router.isReady()
  if (isAuthenticated.value) {
    await initLogin()
  } else if (!router.currentRoute.value.query.code && !router.currentRoute.value.query.state) {
    await loginWithRedirect()
  }
})
</script>

<template>
  <default-template :with-sidebar="$route.meta.withSidebar as boolean">
    <RouterView />
    <p-toast />
    <p-confirm-dialog></p-confirm-dialog>
    <p-dynamic-dialog />
  </default-template>
</template>
